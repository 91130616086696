
export default {
	name: "craMechanism",
	data() {
		return {
			searchMechanism: "", //搜索的机构名称
			token: "",
			tableData: {},
			tableList: [],
			centerDialogVisible: false,
			addSearchMechanism: "",
			page: 1,
			isAddMechanism: '',
			isAdd: '',
			addSearchShortName: '',
			email: '',
			modifyName: false,
			mechanismName: '',
			dataId: '',
			modifyAccount: false,
			gUserEmail: '',
			gUserId: '',
			batchUpload: false,
			httpHeader: {
				token: this.getCookie("token")
			},
			uplaodExcelUrl: '',
			fileList: [],
			updataFail: [],
			loading: false,
			butText: '保存',
			currentPage1: 1
		};
	},

	created() {
		this.token = this.getCookie("token");
		this.uplaodExcelUrl = this.craUrl + "/uploadFile"
		if (this.token) {
			this.getMechanismList(this.page, "");
		}
	},

	methods: {
		getMechanismList(page, name) {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectHistopalList", {
					page: page,
					name: name
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.tableData = res.data.data;
						_this.tableList = res.data.data.records;
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		// 分页
		handleCurrentChange(val) {
			this.page = val;
			this.getMechanismList(this.page, this.searchMechanism);
		},
		// 搜索
		getSearchMechanism(e) {
			this.currentPage1 = 1
			this.getMechanismList(1, this.searchMechanism);
		},
		// 新增机构
		addMechanism(index) {
			this.isAddMechanism = index
			this.centerDialogVisible = true;
			setTimeout(function () {
				var oInput = document.getElementById("xzjg");
				oInput.focus()
			}, 200)
		},
		crcList(index) {
			this.$router.push({
				name: "CrcManageList",
				params: {
					id: this.tableList[index].id
				}
			});
		},
		goCraDepartment(index) {
			this.$router.push({
				name: "CraDepartment",
				params: {
					id: this.tableList[index].id
				}
			});

		},
		// 新增保存
		saveAddMechanism() {
			var _this = this;
			if (!_this.addSearchMechanism) {
				_this.$message({
					message: "请填写新增的机构名称",
					type: "warning"
				});
			} else if (!_this.email) {
				_this.$message({
					message: "请填写新增的机构简称",
					type: "warning"
				});
			} else {
				_this.$http
					.post(
						_this.craUrl + "/addGuserHis", {
						name: _this.addSearchMechanism,
						id: _this.isAddMechanism,
						email: _this.email,
						abbreviation: _this.addSearchShortName
					}, {
						headers: {
							token: _this.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							_this.centerDialogVisible = false;
							_this.getMechanismList(_this.page, _this.searchMechanism);
							_this.addSearchMechanism = ''
							_this.addSearchShortName = ''
							_this.email = ''
						} else if (res.data.code == 401) {
							_this.$router.push("/");
						}
					});
			}
		},
		// 重置密码
		resetPassword(index) {
			var _this = this;
			if (_this.tableList[index].gUserEmail) {
				this.$confirm("此操作将重置该机构的登录密码, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						_this.$http.post(_this.craUrl + '/resetPassword', {
							gUserId: _this.tableList[index].gUserId
						}, {
							headers: {
								"token": _this.token
							}
						}).then(function (res) {
							if (res.data.code == 200) {
								_this.$message({
									type: "success",
									message: "修改成功!"
								});
							}
						})

					})
					.catch(() => {

					});
			} else {
				_this.$message({
					type: "success",
					message: "不可修改!"
				});
			}


		},
		deleteMechanism(index) {
			var _this = this;
			this.$confirm("此操作将永久删除该机构, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					_this.$http.post(_this.craUrl + '/delGuserHis', {
						gUserId: _this.tableList[index].gUserId ? _this.tableList[index].gUserId : '',
						hospitalId: _this.tableList[index].id
					}, {
						headers: {
							"token": _this.token
						}
					}).then(function (res) {
						if (res.data.code == 200) {
							_this.getMechanismList(_this.page, _this.searchMechanism);
							_this.$message({
								type: "success",
								message: "删除成功!"
							});
						}
					})

				})
				.catch(() => {

				});


		},
		// 修改名称
		setMechanismName(index) {
			this.modifyName = true
			this.dataId = this.tableList[index].id
			this.addSearchMechanism = this.tableList[index].name
			this.mechanismName = this.tableList[index].name
			setTimeout(function () {
				var oInput = document.getElementById("bjjg");
				oInput.focus()
			}, 200)
		},
		saveSetMechanism() {
			var that = this
			if (this.mechanismName != this.addSearchMechanism) {
				that.$http
					.post(
						that.craUrl + "/modifyHospital", {
						hospitalId: that.dataId,
						hospitalName: that.addSearchMechanism
					}, {
						headers: {
							token: that.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							that.$message({
								type: "success",
								message: "修改成功!"
							});
							that.modifyName = false
							that.getMechanismList(that.page, that.searchMechanism);
						}
					});
			} else {
				that.modifyName = false
			}
		},
		// 修改关联账号
		setEmileName(index) {
			this.modifyAccount = true
			this.dataId = this.tableList[index].id
			this.gUserId = this.tableList[index].gUserId
			this.email = this.tableList[index].gUserEmail
			this.gUserEmail = this.tableList[index].gUserEmail
			setTimeout(function () {
				var oInput = document.getElementById("bjzh");
				oInput.focus()
			}, 200)
		},
		saveSetemail() {
			var that = this
			if (this.email != this.gUserEmail) {
				that.$http
					.post(
						that.craUrl + "/modifyGuserEmail", {
						hospitalId: that.dataId,
						email: that.email,
						gUserId: that.gUserId ? that.gUserId : ''

					}, {
						headers: {
							token: that.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							that.$message({
								type: "success",
								message: "修改成功!"
							});
							that.modifyAccount = false
							that.getMechanismList(that.page, that.searchMechanism);
						}
					});
			} else {
				that.modifyAccount = false
			}
		},
		// 新增机构
		importMechanism() {
			this.batchUpload = true;
		},
		handleAvatarSuccess(res, file) {
			this.uploadFile = res.data.name;
		},
		// 删除上传文件
		handleRemove(file, fileList) {
			this.uploadFile = ''
		},
		handleExceed(files, fileList) {
			this.$message.warning(`只能上传 1 个文件`);
		},
		// 保存导入的内容
		saveUplaodExcelUrl() {
			var _this = this
			if (_this.uploadFile) {
				_this.loading = true
				_this.butText = '正在导入'
				_this.$http
					.post(
						_this.craUrl + "/uplaodHospitalAndDepementExcel", {
						fileUrl: _this.uploadFile
					}, {
						headers: {
							token: _this.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							_this.loading = false
							_this.butText = '保存'
							_this.updataFail = res.data.data
							_this.getMechanismList(_this.page, '')

							// _this.batchUpload = false
						}
					});
			} else {
				_this.$message({
					message: "请选择导入的文件",
					type: "warning"
				});
			}

		},
	}
};
